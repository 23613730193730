<template>
  <div class="orange-lianer">
    <img src="@/assets/img/icon995.png" class="absolute left0 top0 w100" />
    <div class="container absolute w90">
      <img src="@/assets/img/icon999.png" class="absolute w100 icon999" />
      <img src="@/assets/img/icon996.png" class="absolute w60 icon996" />
      <div class="bgf absolute left0 top0 w100 h100 wrappend">
        <div class="px30 pt10 register">
          <img class="w30 block icon994" src="@/assets/img/icon994.png" />
          <a
            :href="downUrl"
            _target="_blank"
            class="cf tc block w100 lh32px mt30 button"
            >立即下载</a
          >
          <div class="tc mt20 c9 font12">注册成功，点击下载APP ，获得奖金</div>
        </div>
      </div>
    </div>
    <!-- 错误提示框 -->
    <div
      class="fixed top0 left0 w100 h100 opacity-wrapper"
      v-show="hasError"
      @click="clearMsg(0)"
    >
      <div class="fixed tc py50 cf error">{{ msg }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "recommend",
  components: {},
  data() {
    return {
      hasError: false,
      msg: "",
      isAndroid: false,
      isIOS: false,
      downUrl: "",
    };
  },
  methods: {
    showMsg(msg = "") {
      this.msg = msg;
      this.hasError = true;
      this.clearMsg();
    },
    clearMsg(time = 3000) {
      setTimeout(() => {
        this.hasError = false;
      }, time);
    },
  },
  mounted() {
    this.showMsg("注册成功");
    let userAgent = navigator.userAgent;
    let isAndroid =
      userAgent.indexOf("Android") > -1 || userAgent.indexOf("Linux") > -1; //g
    let isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    let downUrl = [
      //ios技师端
      "https://apps.apple.com/cn/app/id1533676456",
      //ios用户端
      "https://apps.apple.com/cn/app/id1533676322",
      //android技师端
      "https://a.app.qq.com/o/simple.jsp?pkgname=com.chumo.app.technician",
      //android用户端
      "https://a.app.qq.com/o/simple.jsp?pkgname=com.chumo.app.user",
    ];
    if (isAndroid) {
      this.downUrl = downUrl[2];
    } else if (isIOS) {
      this.downUrl = downUrl[0];
    }
  },
};
</script>

<style lang="less" scoped>
.orange-lianer {
  height: 100%;
  background: linear-gradient(125deg, #fe562b, #ff9500);
  .container {
    left: 5%;
    bottom: 20px;
    height: 50%;
    .icon999 {
      top: -275px;
    }
    .icon996 {
      top: -110px;
      z-index: 3;
      left: 20%;
    }
    .icon994 {
      margin: 0 auto;
    }
    .wrappend {
      z-index: 2;
      border-radius: 10px;
      .register {
        position: absolute;
        top: 50%;
        height: 260px;
        margin-top: -130px;
      }
    }
  }
}
.button {
  background: #f24849;
  border-radius: 20px;
}
.opacity-wrapper {
  z-index: 5;
  .error {
    top: 50%;
    left: 10%;
    margin-top: -50px;
    width: 80%;
    max-height: 200px;
    background: rgba(0, 0, 0, 0.6);
  }
}
</style>
